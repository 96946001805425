import React, { useState } from "react";

// Components
import { Playlist } from "./Playlist";

// CSS
import "../css/music.scss";

// Constants
const appleMusicPlaylistEmbed = (
    <iframe
        allow="autoplay *; encrypted-media *;"
        frameborder="0"
        height="100%"
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        style={{
            width: "100%",
            overflow: "hidden",
            background: "transparent"
        }}
        src="https://embed.music.apple.com/us/playlist/foreign-air-essentials/pl.36ccf5edf92a4a8e94212cda46a03544"
        title="Foreign Air Apple Music playlist"
    />
);
const spotifyPlaylistEmbed = (
    <iframe
        allow="encrypted-media"
        allowtransparency="true"
        frameborder="0"
        height="100%"
        src="https://open.spotify.com/embed/playlist/4kmUmMdNez070FoX8RNt3m"
        title="Foreign Air Spotify playlist"
        width="100%"
    />
);

export const Music = props => {
    const { isContentHidden } = props;

    const [playlistType, setPlaylistType] = useState("spotify");

    const isAppleMusicSelectedClass =
        playlistType === "apple" ? "music__icons__icon--selected" : "";
    const isHiddenClass = isContentHidden ? "music--hidden" : "";
    const isSpotifySelectedClass =
        playlistType === "spotify" ? "music__icons__icon--selected" : "";
    const playlistEmbed =
        playlistType === "spotify"
            ? spotifyPlaylistEmbed
            : appleMusicPlaylistEmbed;

    return (
        <div className={`music ${isHiddenClass}`}>
            <div className="music__icons">
                <img
                    alt="Spotify logo"
                    className={`music__icons__icon ${isSpotifySelectedClass}`}
                    src={require("../media/logo-spotify.png")}
                    onClick={() => setPlaylistType("spotify")}
                />
                <img
                    alt="Apple Music logo"
                    className={`music__icons__icon ${isAppleMusicSelectedClass}`}
                    src={require("../media/logo-apple-music.png")}
                    onClick={() => setPlaylistType("apple")}
                />
            </div>
            <Playlist embed={playlistEmbed} />
        </div>
    );
};
