import React from "react";

// CSS
import "../css/burger.scss";

export const Burger = props => {
    const { isMenuMobileOpen, setIsMenuMobileOpen } = props;

    const isMenuOpenClass = isMenuMobileOpen ? "burger__line--open" : "";

    function handleToggleMenu() {
        setIsMenuMobileOpen(!isMenuMobileOpen);
    }

    return (
        <div className="burger" onClick={handleToggleMenu}>
            <div className={`burger__line ${isMenuOpenClass}`} />
            <div className={`burger__line ${isMenuOpenClass}`} />
            <div className={`burger__line ${isMenuOpenClass}`} />
        </div>
    );
};
