import React from "react";

// Components
import { Slideshow } from "../components/Slideshow";

// CSS
import "../css/video.scss";

// Constants
const videos = [
    { src: "https://www.youtube.com/embed/wso_3zw-ijw" },
    { src: "https://www.youtube.com/embed/TLUTxZe0OuA" },
    { src: "https://www.youtube.com/embed/4eUspNrvHM4" },
    { src: "https://www.youtube.com/embed/ukUie7a3O0w" },
    { src: "https://www.youtube.com/embed/yY7a4lCmTqw" },
    { src: "https://www.youtube.com/embed/gBACW3D8uAE" },
    { src: "https://www.youtube.com/embed/yUwbIulxkp0" },
    { src: "https://www.youtube.com/embed/doVbHydheb4" },
];

export const Video = (props) => {
    const { isContentHidden } = props;
    const isHiddenClass = isContentHidden ? "video--hidden" : "";

    return (
        <div className={`video ${isHiddenClass}`}>
            <Slideshow collection={videos} mediaType="youTube" />
        </div>
    );
};
