import React from "react";
import { Modal } from "react-bootstrap";

// CSS
import "../css/email-modal.scss";

export const EmailModal = props => {
    const { showModal, setShowModal } = props;

    function handleHideModal() {
        setShowModal(false);
    }

    return (
        <Modal
            centered
            className="email-modal"
            show={showModal}
            onHide={handleHideModal}>
            <Modal.Header closeButton />
            <Modal.Body>
                <div className="email-modal__header">
                    <span>SIGN UP FOR EMAIL UPDATES</span>
                </div>
                <div className="email-modal__body">
                    <span>GET THE LATEST FOREIGN AIR NEWS IN YOUR INBOX</span>
                </div>
                <div id="mc_embed_signup">
                    <form
                        action="https://foreignair.us12.list-manage.com/subscribe/post?u=11cd840cb78839bb863175599&amp;id=ed1c1d63d0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="email-modal__form validate"
                        target="_blank"
                        noValidate>
                        <div className="mc-field-group mc-field-group-email">
                            <input
                                className="email-modal__form__input required email"
                                id="mce-EMAIL"
                                name="EMAIL"
                                placeholder="Email"
                                type="email"
                            />
                        </div>
                        <div className="mc-field-group mc-field-group-fname">
                            <input
                                className="email-modal__form__input"
                                id="mce-FNAME"
                                name="FNAME"
                                placeholder="First Name"
                                type="text"
                            />
                        </div>
                        <div className="mc-field-group mc-field-group-lname">
                            <input
                                className="email-modal__form__input"
                                id="mce-LNAME"
                                name="LNAME"
                                placeholder="Last Name"
                                type="text"
                            />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: "none" }}
                            />
                            <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: "none" }}
                            />
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                left: "-5000px"
                            }}
                            aria-hidden="true">
                            <input
                                type="text"
                                name="b_11cd840cb78839bb863175599_ed1c1d63d0"
                                tabIndex="-1"
                            />
                        </div>
                        <input
                            type="submit"
                            value="SIGN UP"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                            className="email-modal__form__button button"
                        />
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
