import React from "react";

// CSS
import "../css/tour.scss";

export const Tour = (props) => {
    return (
        <div className="tour">
            <div
                id="seated-55fdf2c0"
                data-artist-id="9cf65590-e579-4386-bbd6-c31c932c3e40"
                data-css-version="2"
            ></div>
        </div>
    );
};
