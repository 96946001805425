import React from "react";

// CSS
import "../css/main.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

export const Main = (props) => {
    const { isContentHidden } = props;
    const isHiddenClass = isContentHidden ? "main--hidden" : "";

    function renderBandName() {
        return isMobile() ? (
            <div className="main__center__middle">
                <img
                    alt="Foreign Air logo"
                    className="main__center__middle__logo"
                    src={require("../media/logo-foreign-air.png")}
                />
            </div>
        ) : (
            <div className="main__center__middle">
                <span>FOREIGN AIR</span>
            </div>
        );
    }

    return (
        <div className={`main ${isHiddenClass}`}>
            <div className="main__center">
                {renderBandName()}
                <div className="main__center__bottom">
                    <a
                        href="https://linktr.ee/ForeignAir"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <span>HAPPY HAPPY JOY JOY CLUB</span>
                    </a>
                </div>
            </div>
            <div className="main__corner main__corner--bottom-right">
                <span>A&#8209;9243B1</span>
            </div>
        </div>
    );
};
