import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

// CSS
import "../css/menu.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

// Constants
const smileyFaceImage = require("../media/smiley-face.png");

const Menu = props => {
    const { setCurrentPage, setIsMenuMobileOpen } = props;
    const [hoverStates, setHoverStates] = useState([
        false,
        false,
        false,
        false
    ]);

    function handleGoToMusic() {
        setIsMenuMobileOpen && setIsMenuMobileOpen(false);
        setCurrentPage("music");
        props.history.push("/music");
    }

    function handleGoToTour() {
        setIsMenuMobileOpen && setIsMenuMobileOpen(false);
        setCurrentPage("tour");
        props.history.push("/tour");
    }

    function handleGoToVideo() {
        setIsMenuMobileOpen && setIsMenuMobileOpen(false);
        setCurrentPage("video");
        props.history.push("/video");
    }

    function handleToggleHoverState(bool, i) {
        const hoverStatesCopy = [...hoverStates];
        hoverStatesCopy[i] = bool;
        setHoverStates(hoverStatesCopy);
    }

    function renderATGStudiosTag() {
        return (
            <div className="menu__atg-studios-tag">
                <span>BUILT BY ATG STUDIOS</span>
            </div>
        );
    }

    function renderMenuItems() {
        const items = [
            {
                classes: "menu__item menu__item--first",
                color: "orange",
                events: { onClick: handleGoToTour },
                images: { stickerSrc: require("../media/circle-orange.png") },
                text: "Tour"
            },
            {
                classes: "menu__item menu__item--second",
                color: "yellow",
                events: { onClick: handleGoToMusic },
                images: { stickerSrc: require("../media/circle-yellow.png") },
                text: "Music"
            },
            {
                classes: "menu__item menu__item--third",
                color: "green",
                events: { onClick: handleGoToVideo },
                images: { stickerSrc: require("../media/circle-green.png") },
                text: "Video"
            },
            {
                classes: "menu__item menu__item--fourth",
                color: "pink",
                events: { onClick: handleGoToMerch },
                images: { stickerSrc: require("../media/circle-pink.png") },
                text: "Merch"
            }
        ];
        return items.map((item, i) => {
            const { classes, color, events, images } = item;
            return (
                <div
                    className={classes}
                    key={i}
                    onClick={events.onClick}
                    onMouseEnter={() => handleToggleHoverState(true, i)}
                    onMouseLeave={() => handleToggleHoverState(false, i)}>
                    <img
                        alt={`${color} circle`}
                        className="menu__item__circle"
                        src={images.stickerSrc}
                    />
                    {renderMenuItemContent(item, i)}
                </div>
            );
        });
    }

    function renderMenuItemContent(item, i) {
        const isHovering = hoverStates[i];
        return isHovering && !isMobile() ? (
            <img
                alt="Foreign Air smiley face"
                className="menu__item__smiley"
                src={smileyFaceImage}
            />
        ) : (
            <div className="menu__item__text">
                <span>{item.text}</span>
            </div>
        );
    }

    return (
        <div className="menu">
            {renderMenuItems()}
            {renderATGStudiosTag()}
        </div>
    );
};

export const MenuWithRouter = withRouter(Menu);

export const MenuMobile = props => {
    const { isMenuMobileOpen, setIsMenuMobileOpen } = props;

    const isMenuOpenClass = isMenuMobileOpen ? "menu-mobile--open" : "";

    useEffect(() => {
        if (isMenuMobileOpen) {
            document.body.style.position = "fixed";
            document.body.style.top = `-${window.scrollY}px`;
        } else {
            document.body.style.position = "";
            document.body.style.top = "";
        }
    }, [isMenuMobileOpen]);

    return (
        <div className={`menu-mobile ${isMenuOpenClass}`}>
            <MenuWithRouter
                {...props}
                setIsMenuMobileOpen={setIsMenuMobileOpen}
            />
        </div>
    );
};

function handleGoToMerch() {
    window.open("https://foreignair.merchmadeeasy.com/");
}
