import React from "react";
import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";

// Components
import App from "./App";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");
render(<App />, rootElement);

serviceWorker.unregister();
