import React from "react";

// CSS
import "../css/social-buttons.scss";

export const SocialButtons = props => {
    const { setShowModal } = props;

    function handleShowEmailModal() {
        setShowModal(true);
    }

    return (
        <div className="social-buttons">
            <img
                alt="Instagram logo"
                src={require("../media/logo-instagram.png")}
                onClick={handleGoToInstagram}
            />
            <img
                alt="Facebook logo"
                src={require("../media/logo-facebook.png")}
                onClick={handleGoToFacebook}
            />
            <img
                alt="Twitter logo"
                src={require("../media/logo-twitter.png")}
                onClick={handleGoToTwitter}
            />
            <img
                alt="Youtube logo"
                src={require("../media/logo-youtube.png")}
                onClick={handleGoToYoutube}
            />
            <img
                alt="Email logo"
                src={require("../media/logo-email.png")}
                onClick={handleShowEmailModal}
            />
        </div>
    );
};

function handleGoToInstagram() {
    window.open("https://www.instagram.com/foreign_air");
}

function handleGoToFacebook() {
    window.open("https://www.facebook.com/foreignair/");
}

function handleGoToTwitter() {
    window.open("https://twitter.com/ForeignAir");
}

function handleGoToYoutube() {
    window.open("https://www.youtube.com/channel/UCKwXuTVHDGgLnMYdIkjZyYg");
}
