import React from "react";
import { Link } from "react-router-dom";

// Components
import { Burger } from "./Burger";

// CSS
import "../css/header-bar.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

export const HeaderBar = props => {
    const { isMenuMobileOpen, setCurrentPage, setIsMenuMobileOpen } = props;

    function handleClickSticker() {
        setCurrentPage("main");
        setIsMenuMobileOpen(false);
    }

    function renderLogo() {
        return isMobile() ? (
            <Link to="/">
                <span className="header-bar__sticker">FOREIGN AIR</span>
            </Link>
        ) : (
            <Link to="/">
                <img
                    alt="Foreign Air sticker"
                    className="header-bar__sticker"
                    src={require("../media/sticker-pink.png")}
                    onClick={handleClickSticker}
                />
            </Link>
        );
    }

    function renderRight() {
        return isMobile() ? (
            <Burger
                isMenuMobileOpen={isMenuMobileOpen}
                setIsMenuMobileOpen={setIsMenuMobileOpen}
            />
        ) : (
            <div className="header-bar__text">
                <span>TEXT LINE: (310) 347-4617</span>
            </div>
        );
    }

    return (
        <div className="header-bar">
            {renderLogo()}
            {renderRight()}
        </div>
    );
};
