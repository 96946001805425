import React, { useState } from "react";

// CSS
import "../css/slideshow.scss";

export const Slideshow = props => {
    const { collection, mediaType } = props;
    const [currentPosition, setCurrentPosition] = useState(0);
    const [isCurrentItemHidden, setIsCurrentItemHidden] = useState(false);

    const currentItem = collection[currentPosition];
    const isCurrentItemHiddenClass = isCurrentItemHidden ? "hidden" : "";
    const leftArrowDisableClass =
        currentPosition === 0 ? "slideshow__arrows__arrow__left--disable" : "";
    const rightArrowDisableClass =
        currentPosition === collection.length - 1
            ? "slideshow__arrows__arrow__right--disable"
            : "";

    function handleChangeCurrentItem(diff) {
        const newPosition = currentPosition + diff;
        if (collection[newPosition]) {
            if (mediaType === "image") {
                setIsCurrentItemHidden(true);
                setTimeout(() => {
                    setCurrentPosition(newPosition);
                    setIsCurrentItemHidden(false);
                }, 250);
            } else {
                setCurrentPosition(newPosition);
            }
        }
    }

    function handleGoToLink() {
        window.open(currentItem.link);
    }

    function renderImage() {
        return (
            <React.Fragment>
                <div
                    className={`slideshow__image ${isCurrentItemHiddenClass}`}
                    onClick={handleGoToLink}
                    style={{
                        backgroundImage: `url(${currentItem.image.src})`
                    }}
                />
                <iframe
                    allow="encrypted-media"
                    allowtransparency="true"
                    className={`slideshow__spotify ${isCurrentItemHiddenClass}`}
                    frameborder="0"
                    src={`https://open.spotify.com/embed/track/${currentItem.embedId}`}
                    title="Foreign Air Spotify embed"
                />
            </React.Fragment>
        );
    }

    function renderYouTube() {
        return (
            <div className="slideshow__youtube">
                <iframe
                    className="slideshow__youtube__embed"
                    src={currentItem.src}
                    title="Foreign Air video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        );
    }

    function renderMedia() {
        switch (mediaType) {
            case "image":
                return renderImage();
            case "youTube":
                return renderYouTube();
            default:
                return null;
        }
    }

    return (
        <div className="slideshow">
            <div className="slideshow__arrows">
                <div
                    className={`slideshow__arrows__arrow slideshow__arrows__arrow__left ${leftArrowDisableClass}`}
                    onClick={() => handleChangeCurrentItem(-1)}
                />
                <div
                    className={`slideshow__arrows__arrow slideshow__arrows__arrow__right ${rightArrowDisableClass}`}
                    onClick={() => handleChangeCurrentItem(1)}
                />
            </div>
            {renderMedia()}
        </div>
    );
};
