import React from "react";

// CSS
import "../css/playlist.scss";

export const Playlist = props => {
    const { embed } = props;

    return <div className="playlist">{embed}</div>;
};
