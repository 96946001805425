import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import cx from "classnames";

// Components
import { EmailModal } from "./components/EmailModal";
import { HeaderBar } from "./components/HeaderBar";
import { Main } from "./components/Main";
import { MenuWithRouter as Menu, MenuMobile } from "./components/Menu";
import { Music } from "./components/Music";
import { SocialButtons } from "./components/SocialButtons";
import { Tour } from "./components/Tour";
import { Video } from "./components/Video";

// CSS
import "./css/app.scss";
import "./css/content.scss";
import "./css/reset.scss";

// Platforms
import { BandsInTown } from "./platforms/BandsInTown";

// Utils
import { isMobile } from "./utils/mediaQueryUtils";

function App(props) {
    const [currentPage, setCurrentPage] = useState("main");
    const [isContentHidden, setIsContentHidden] = useState(false);
    const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [shows, setShows] = useState(null);

    const isTourRoute = window.location.pathname.includes("tour");

    // Fetch tour dates once component renders
    useEffect(() => {
        BandsInTown.fetchShows(onFetchShowsSuccess, onFetchShowsFailure);
    }, []);

    // Auto display email signup modal five seconds after page load
    useEffect(() => {
        setTimeout(() => {
            const hasVisitedSite = sessionStorage.getItem("hasVisitedSite");
            if (!hasVisitedSite) {
                setShowModal(true);
                sessionStorage.setItem("hasVisitedSite", true);
            }
        }, 5000);
    }, []);

    function changePage(key) {
        setIsContentHidden(true);
        setTimeout(() => {
            setCurrentPage(key);
            setIsContentHidden(false);
        }, 250);
    }

    function onFetchShowsFailure(err) {
        setShows([]);
    }

    function onFetchShowsSuccess(shows) {
        setShows(shows);
    }

    function renderCurrentPage(key) {
        return (
            <Switch>
                <Route path="/music">
                    <Music isContentHidden={isContentHidden} />
                </Route>
                <Route path="/tour">
                    <Tour isContentHidden={isContentHidden} shows={shows} />
                </Route>
                <Route path="/video">
                    <Video isContentHidden={isContentHidden} />
                </Route>
                <Route path="/">
                    <Main
                        isContentHidden={isContentHidden}
                        setShowModal={setShowModal}
                    />
                </Route>
            </Switch>
        );
    }

    function renderMenu() {
        return isMobile() ? (
            <MenuMobile
                setCurrentPage={setCurrentPage}
                isMenuMobileOpen={isMenuMobileOpen}
                setIsMenuMobileOpen={setIsMenuMobileOpen}
            />
        ) : (
            <Menu setCurrentPage={changePage} />
        );
    }

    return (
        <Router>
            <div className="app">
                <EmailModal showModal={showModal} setShowModal={setShowModal} />
                {renderMenu()}
                <div
                    className={cx("content", {
                        "content--expanded": isTourRoute,
                    })}
                >
                    <HeaderBar
                        isMenuMobileOpen={isMenuMobileOpen}
                        setCurrentPage={changePage}
                        setIsMenuMobileOpen={setIsMenuMobileOpen}
                    />
                    {renderCurrentPage(currentPage)}
                    <SocialButtons setShowModal={setShowModal} />
                </div>
            </div>
        </Router>
    );
}

export default App;
