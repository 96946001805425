const appId = process.env.REACT_APP_BANDSINTOWN_APP_ID;

export class BandsInTown {
    static fetchShows(callback, onFailure) {
        const url = `https://rest.bandsintown.com/artists/foreign%20air/events?app_id=${appId}`;
        return fetch(url)
            .then(res => res.json())
            .then(res => callback(res))
            .catch(err => onFailure(err));
    }
}
